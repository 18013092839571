import { useApplicationContext } from '@/context/ApplicationContext';
import useTranslation from '@/utils/i18n/useTranslation';
import { gql } from '@apollo/client';
import { Button } from '@chakra-ui/react';
import { useMemo } from 'react';
import CustomSearchableSelect, { CustomSearchableSelectOption } from './CustomSearchableSelect';
import { SuspenseWithSpinner } from './SuspenseWithSpinner';
import { useUserSelectInputSuspenseQuery } from './UserSelectInput.generated';

export type UserType = {
  userId: string;
};

export type UserSelectInputProps = {
  values: UserType[];
  onChange: (usersValues: UserType[]) => void;
  label?: string;
};

type OptionType = Omit<CustomSearchableSelectOption, 'isChecked'>;

gql`
  query UserSelectInput {
    users {
      id
      name
    }
  }
`;

const UserSelectInput = (props: UserSelectInputProps) => {
  const { me } = useApplicationContext();
  const {
    data: { users },
  } = useUserSelectInputSuspenseQuery();
  const { t } = useTranslation();
  const { values, onChange, label = t('assignee') } = props;

  const selectedUsers = useMemo(() => {
    if (users.length === 0) return [];
    return values.map((value) => {
      const user = users.find((user) => user.id === value.userId);
      if (!user) throw new Error('user not found');
      return {
        value: user.id,
        label: user.name,
      };
    });
  }, [users, values]);

  if (users === undefined) return null;

  const options = users.map((user) => ({ value: user.id, label: user.name }));

  const handleInput = (values: CustomSearchableSelectOption[]) => {
    onChange(values.map(({ value }) => ({ userId: value as string })));
  };

  const onAssignToMe = () => {
    if (!me) return;

    const myValue: OptionType = { value: me.id, label: me.name };
    handleInput([...selectedUsers, myValue]);
  };

  const isShowAssignToMe = me && !selectedUsers.find((user) => user.value === me.id);

  return (
    <>
      <CustomSearchableSelect
        label={label}
        placeholder={t('warning.please-select')}
        value={selectedUsers}
        onChange={handleInput}
        options={options}
        isClearable
        isMulti
      />
      {isShowAssignToMe && (
        <Button size='sm' variant='link' colorScheme='primary' p='1' onClick={onAssignToMe}>
          {t('assign-to-me')}
        </Button>
      )}
    </>
  );
};

const UserSelectInputWithSpinner = (props: UserSelectInputProps) => (
  <SuspenseWithSpinner>
    <UserSelectInput {...props} />
  </SuspenseWithSpinner>
);

export default UserSelectInputWithSpinner;
